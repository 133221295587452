import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './footer.scss';
// import { Link } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";
const Footer = () => {
    const { hash } = useLocation();
    const isActive = (iHash) => hash === iHash;
    return (
        <>
            <section className="main-footer ">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-xl-12 col-sm-12 m-auto  '>
                            <div className='fotrmaindiv'>
                                <Link to="/">
                                    {/* <img
                                        src="\Logo\Logo.png"
                                        alt="img"
                                        width={'204px'}
                                        className="img-fluid animate__animated animate__bounce"
                                    /> */}

                                    <svg xmlns="http://www.w3.org/2000/svg" width="148" height="30" viewBox="0 0 148 30" fill="none">
                                        <path d="M0 29.7833C7.82462 19.5316 22.2343 11.5337 22.2343 11.5337C22.2343 11.5337 37.2706 19.6677 44.4687 29.7833C37.2744 13.3943 22.2343 0.216553 22.2343 0.216553C22.2343 0.216553 7.82462 13.1717 0 29.7833Z" fill="#FF8A00" />
                                        <path d="M0.113892 29.6558C7.94044 25.3187 22.3482 21.9346 22.3482 21.9346C22.3482 21.9346 37.3845 25.377 44.5826 29.6558C37.3902 22.7212 22.3482 17.1479 22.3482 17.1479C22.3482 17.1479 7.94044 22.6282 0.113892 29.6558Z" fill="#FF8A00" />
                                        <path d="M0.113892 29.656C7.94044 27.8213 22.3482 26.3842 22.3482 26.3842C22.3482 26.3842 37.3845 27.8385 44.5826 29.6495C37.3902 26.717 22.3482 24.3572 22.3482 24.3572C22.3482 24.3572 7.94044 26.6824 0.113892 29.656Z" fill="#FF8A00" />
                                        <path d="M58.149 22.868C54.8039 22.868 52.6009 21.2915 52.4291 18.674L52.4189 18.5225H54.9151L54.9353 18.6437C55.1273 19.8969 56.4108 20.675 58.2399 20.675C60.0792 20.675 61.2312 19.8564 61.2312 18.6033V18.5932C61.2312 17.5018 60.4733 16.9156 58.5936 16.5215L57.0272 16.1981C54.1571 15.6019 52.7423 14.2679 52.7423 12.0951V12.085C52.7524 9.51816 55.006 7.77995 58.149 7.77995C61.3323 7.77995 63.4141 9.45752 63.5657 11.9537L63.5758 12.1255H61.0796L61.0594 12.0042C60.8876 10.7309 59.7659 9.97292 58.1287 9.97292C56.4209 9.98303 55.3597 10.7814 55.3597 11.9436V11.9537C55.3597 12.9845 56.1581 13.5908 57.9165 13.9546L59.493 14.278C62.5248 14.9046 63.8486 16.1375 63.8486 18.4113V18.4214C63.8486 21.1399 61.7062 22.868 58.149 22.868Z" fill="#10312B" />
                                        <path d="M65.5464 22.6153V11.9031H68.0628V13.6211H68.1133C68.5681 12.4489 69.6696 11.6808 71.0844 11.6808C72.5801 11.6808 73.6614 12.4691 74.0555 13.8435H74.1162C74.6316 12.5095 75.9049 11.6808 77.4511 11.6808C79.5632 11.6808 80.9477 13.1158 80.9477 15.2583V22.6153H78.4314V15.8848C78.4314 14.561 77.7442 13.803 76.5113 13.803C75.2986 13.803 74.4699 14.7025 74.4699 15.996V22.6153H72.0243V15.7535C72.0243 14.5509 71.2967 13.803 70.1244 13.803C68.9117 13.803 68.0628 14.753 68.0628 16.0566V22.6153H65.5464Z" fill="#10312B" />
                                        <path d="M86.132 22.7972C83.9896 22.7972 82.5444 21.4734 82.5444 19.5634V19.5431C82.5444 17.6433 84.0199 16.5013 86.6171 16.3396L89.4164 16.1678V15.4301C89.4164 14.3487 88.709 13.6919 87.4357 13.6919C86.2432 13.6919 85.5156 14.2477 85.3539 15.0359L85.3337 15.1269H82.9992L83.0093 15.0056C83.1609 13.1057 84.8082 11.6808 87.5266 11.6808C90.2047 11.6808 91.9328 13.0956 91.9328 15.2482V22.6153H89.4164V20.9681H89.3558C88.7393 22.0898 87.5468 22.7972 86.132 22.7972ZM85.0507 19.4623C85.0507 20.3415 85.7783 20.8771 86.8799 20.8771C88.325 20.8771 89.4164 19.9171 89.4164 18.6437V17.8353L86.9708 17.9869C85.7278 18.0677 85.0507 18.6033 85.0507 19.4522V19.4623Z" fill="#10312B" />
                                        <path d="M93.964 22.6153V11.9031H96.4804V13.7525H96.5309C96.8644 12.459 97.7537 11.6808 98.9866 11.6808C99.2999 11.6808 99.593 11.7313 99.785 11.7819V14.0557C99.5728 13.9748 99.1888 13.9142 98.7643 13.9142C97.3394 13.9142 96.4804 14.8136 96.4804 16.4002V22.6153H93.964Z" fill="#10312B" />
                                        <path d="M105.06 22.8275C102.817 22.8275 101.756 21.918 101.756 19.7149V13.8435H100.24V11.9031H101.756V9.18467H104.312V11.9031H106.303V13.8435H104.312V19.5027C104.312 20.5537 104.777 20.8872 105.697 20.8872C105.939 20.8872 106.131 20.8569 106.303 20.8468V22.7366C106.01 22.777 105.566 22.8275 105.06 22.8275Z" fill="#10312B" />
                                        <path d="M118.774 22.868C114.58 22.868 111.932 19.9777 111.932 15.3189V15.3088C111.932 10.65 114.59 7.77995 118.774 7.77995C122.129 7.77995 124.595 9.87186 124.979 12.843L124.989 12.944H122.432L122.382 12.752C121.957 11.0846 120.674 10.0437 118.774 10.0437C116.227 10.0437 114.6 12.0749 114.6 15.3088V15.3189C114.6 18.5629 116.237 20.6043 118.774 20.6043C120.633 20.6043 121.937 19.6038 122.412 17.8049L122.442 17.7039H124.999L124.979 17.8049C124.605 20.7963 122.129 22.868 118.774 22.868Z" fill="#10312B" />
                                        <path d="M128.061 10.367C127.273 10.367 126.656 9.74049 126.656 8.99265C126.656 8.22461 127.273 7.60815 128.061 7.60815C128.849 7.60815 129.456 8.22461 129.456 8.99265C129.456 9.74049 128.849 10.367 128.061 10.367ZM126.798 22.6153V11.9031H129.314V22.6153H126.798Z" fill="#10312B" />
                                        <path d="M135.438 22.8275C133.195 22.8275 132.134 21.918 132.134 19.7149V13.8435H130.618V11.9031H132.134V9.18467H134.691V11.9031H136.681V13.8435H134.691V19.5027C134.691 20.5537 135.155 20.8872 136.075 20.8872C136.318 20.8872 136.51 20.8569 136.681 20.8468V22.7366C136.388 22.777 135.944 22.8275 135.438 22.8275Z" fill="#10312B" />
                                        <path d="M139.845 26.1726C139.359 26.1726 138.854 26.1119 138.551 26.0614V24.1413C138.743 24.1817 139.036 24.2323 139.39 24.2323C140.35 24.2323 140.885 23.9594 141.209 23.06L141.36 22.6254L137.51 11.9031H140.259L142.765 20.4729H142.836L145.352 11.9031H148L144.15 22.9791C143.331 25.3338 142.078 26.1726 139.845 26.1726Z" fill="#10312B" />
                                    </svg>
                                </Link>
                                <div className="fotrlinks">


                             

                                        <HashLink className="ftrpara" to="#navbar"
                                            style={
                                                isActive("")
                                                    ? {
                                                        color: "#FF8A00",
                                                    }
                                                    : {}}
                                        >Home</HashLink>
                                  

                                        <HashLink className="ftrpara" to="#about"
                                            style={
                                                isActive("#about")
                                                    ? {
                                                        color: "#FF8A00",
                                                    }
                                                    : {}}

                                        >About us</HashLink>
                                
                                        

                                        <HashLink className="ftrpara" to="#benefits"
                                            style={
                                                isActive("#benefits")
                                                    ? {
                                                        color: "#FF8A00",
                                                    }
                                                    : {}}

                                        >Benefits</HashLink>
                              

                                        <HashLink className="ftrpara" to="#information"
                                            style={
                                                isActive("#information")
                                                    ? {
                                                        color: "#FF8A00",
                                                    }
                                                    : {}}

                                        >NFT</HashLink>
                               
                                        <HashLink className="ftrpara" to="#roadmap"
                                            style={
                                                isActive("#roadmap")
                                                    ? {
                                                        color: "#FF8A00",
                                                    }
                                                    : {}}

                                        >
                                            Roadmap</HashLink>
                             
                                        <HashLink className="ftrpara" to="#ourteamsec"
                                            style={
                                                isActive("#ourteamsec")
                                                    ? {
                                                        color: "#FF8A00",
                                                    }
                                                    : {}}

                                        >
                                            Team</HashLink>
                                
                                        <HashLink className="ftrpara" to="#faqs"
                                            style={
                                                isActive("#faqs")
                                                    ? {
                                                        color: "#FF8A00",
                                                    }
                                                    : {}}

                                        >
                                            FAQ</HashLink>
                               
                                        <HashLink className="ftrpara" to="#"
                                            style={
                                                isActive("#")
                                                    ? {
                                                        color: "#FF8A00",
                                                    }
                                                    : {}}

                                        >
                                            Whitepaper</HashLink>
                            

                                </div>
                      
                                <div className='ftrsocallinks'>
                                    <div className='innnnner_footer'>
                                        <a

                                            href="#"
                                            target="_blank"
                                            className='twinsbtnssfooter'
                                        >
                                            <img src='\assets\footer\twiter.svg' className='' />



                                            {/* <button className="twinsbtnssfooter">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                    <path d="M10.3822 1.71875H12.174L8.25939 6.19292L12.8646 12.2812H9.25876L6.43451 8.58871L3.20293 12.2812H1.41001L5.59709 7.49563L1.17926 1.71875H4.87668L7.42955 5.09388L10.3822 1.71875ZM9.7533 11.2088H10.7462L4.33718 2.73492H3.27172L9.7533 11.2088Z" fill="#10312B" />
                                                </svg>
                                            </button> */}
                                        </a>
                                        <a

                                            href="#"
                                            target="_blank"
                                            className='twinsbtnssfooter'
                                        >
                                            <img src='\assets\footer\telegram.svg' className='' />
                                            {/* <button className="twinsbtnssfooter">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                                                    <path d="M15.3174 1.95185L13.0962 12.4267C12.9287 13.166 12.4917 13.35 11.8707 13.0017L8.4864 10.5078L6.85341 12.0784C6.6727 12.2591 6.52155 12.4102 6.17327 12.4102L6.41641 8.96354L12.6888 3.29571C12.9615 3.05256 12.6297 2.91785 12.265 3.16099L4.51071 8.04354L1.17243 6.99869C0.446293 6.77198 0.43315 6.27255 1.32358 5.92427L14.381 0.893859C14.9855 0.667145 15.5145 1.02857 15.3174 1.95185Z" fill="#10312B" />
                                                </svg>
                                            </button> */}
                                        </a>
                                    </div>


                                    <div className='btmftors'>
                                        <p className='btmpataftr'>
                                            © 2024 . All Rights Reserved.
                                        </p>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Footer;
