import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";

import "./navbarmint.scss";
import { Link, useLocation } from "react-router-dom";


import { HashLink } from 'react-router-hash-link';

// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Copied = styled("span")`
  font-size: 16px;
  margin-left: 10px;
`;
const HOVER_TIMEOUT = 1000;

const Navbarmint = () => {

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggleBtn, setToggleBtn] = useState(false)
  console.log("🚀 ~ file: Navbar.js ~ line 21 ~ Navbar ~ toggleBtn", toggleBtn)







  const [showsidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  return (
    <section className="main-navbar-mobile   main_navbarmint">
      <div className="container-fluid  ">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12  m-auto    nav_boder ">
            <nav className="navbar  navbar-expand-xl ">
              <a href="#" className="navbar-brand animate__flash" >
                <Link to="/">
                  {/* <img
                    src="\Logo\Logo.png"
                    alt="img"
                    width={'204px'}
                    className="img-fluid animate__animated animate__bounce"
                  /> */}


                  <svg xmlns="http://www.w3.org/2000/svg" width="148" height="30" viewBox="0 0 148 30" fill="none">
                    <path d="M0 29.5668C7.82462 19.315 22.2343 11.3172 22.2343 11.3172C22.2343 11.3172 37.2706 19.4512 44.4687 29.5668C37.2744 13.1778 22.2343 0 22.2343 0C22.2343 0 7.82462 12.9552 0 29.5668Z" fill="#FF8A00" />
                    <path d="M0.113922 29.4393C7.94047 25.1021 22.3483 21.718 22.3483 21.718C22.3483 21.718 37.3845 25.1605 44.5826 29.4393C37.3903 22.5046 22.3483 16.9314 22.3483 16.9314C22.3483 16.9314 7.94047 22.4117 0.113922 29.4393Z" fill="#FF8A00" />
                    <path d="M0.113922 29.4394C7.94047 27.6047 22.3483 26.1676 22.3483 26.1676C22.3483 26.1676 37.3845 27.622 44.5826 29.4329C37.3903 26.5004 22.3483 24.1406 22.3483 24.1406C22.3483 24.1406 7.94047 26.4659 0.113922 29.4394Z" fill="#FF8A00" />
                    <path d="M58.149 22.6514C54.804 22.6514 52.6009 21.0749 52.4291 18.4575L52.419 18.3059H54.9151L54.9353 18.4272C55.1273 19.6803 56.4108 20.4585 58.2399 20.4585C60.0792 20.4585 61.2313 19.6399 61.2313 18.3868V18.3766C61.2313 17.2852 60.4733 16.6991 58.5936 16.305L57.0272 15.9816C54.1572 15.3853 52.7424 14.0513 52.7424 11.8786V11.8685C52.7525 9.3016 55.0061 7.5634 58.149 7.5634C61.3323 7.5634 63.4141 9.24097 63.5657 11.7371L63.5758 11.9089H61.0797L61.0595 11.7876C60.8877 10.5143 59.7659 9.75637 58.1288 9.75637C56.4209 9.76647 55.3598 10.5648 55.3598 11.727V11.7371C55.3598 12.7679 56.1581 13.3743 57.9166 13.7381L59.4931 14.0615C62.5248 14.688 63.8487 15.9209 63.8487 18.1947V18.2048C63.8487 20.9233 61.7062 22.6514 58.149 22.6514Z" fill="#10312B" />
                    <path d="M65.5465 22.3988V11.6866H68.0628V13.4046H68.1133C68.5681 12.2323 69.6696 11.4643 71.0845 11.4643C72.5801 11.4643 73.6614 12.2525 74.0556 13.6269H74.1162C74.6316 12.2929 75.9049 11.4643 77.4511 11.4643C79.5633 11.4643 80.9478 12.8993 80.9478 15.0417V22.3988H78.4314V15.6683C78.4314 14.3444 77.7442 13.5865 76.5113 13.5865C75.2986 13.5865 74.4699 14.4859 74.4699 15.7794V22.3988H72.0243V15.5369C72.0243 14.3343 71.2967 13.5865 70.1244 13.5865C68.9117 13.5865 68.0628 14.5364 68.0628 15.8401V22.3988H65.5465Z" fill="#10312B" />
                    <path d="M86.1321 22.5807C83.9896 22.5807 82.5445 21.2568 82.5445 19.3468V19.3266C82.5445 17.4267 84.0199 16.2847 86.6171 16.123L89.4165 15.9512V15.2135C89.4165 14.1322 88.709 13.4753 87.4357 13.4753C86.2432 13.4753 85.5156 14.0311 85.3539 14.8194L85.3337 14.9103H82.9992L83.0093 14.7891C83.1609 12.8892 84.8082 11.4643 87.5267 11.4643C90.2047 11.4643 91.9328 12.8791 91.9328 15.0316V22.3988H89.4165V20.7515H89.3558C88.7394 21.8733 87.5469 22.5807 86.1321 22.5807ZM85.0507 19.2458C85.0507 20.125 85.7783 20.6606 86.8799 20.6606C88.325 20.6606 89.4165 19.7005 89.4165 18.4272V17.6187L86.9708 17.7703C85.7278 17.8511 85.0507 18.3868 85.0507 19.2356V19.2458Z" fill="#10312B" />
                    <path d="M93.9641 22.3988V11.6866H96.4804V13.536H96.531C96.8645 12.2424 97.7538 11.4643 98.9867 11.4643C99.3 11.4643 99.593 11.5148 99.785 11.5653V13.8391C99.5728 13.7583 99.1888 13.6976 98.7644 13.6976C97.3394 13.6976 96.4804 14.5971 96.4804 16.1837V22.3988H93.9641Z" fill="#10312B" />
                    <path d="M105.06 22.611C102.817 22.611 101.756 21.7015 101.756 19.4984V13.6269H100.24V11.6866H101.756V8.96811H104.312V11.6866H106.303V13.6269H104.312V19.2862C104.312 20.3372 104.777 20.6707 105.697 20.6707C105.939 20.6707 106.132 20.6404 106.303 20.6302V22.52C106.01 22.5605 105.566 22.611 105.06 22.611Z" fill="#10312B" />
                    <path d="M118.774 22.6514C114.58 22.6514 111.932 19.7611 111.932 15.1024V15.0923C111.932 10.4335 114.59 7.5634 118.774 7.5634C122.129 7.5634 124.595 9.65531 124.979 12.6264L124.989 12.7275H122.432L122.382 12.5355C121.957 10.868 120.674 9.82711 118.774 9.82711C116.227 9.82711 114.6 11.8584 114.6 15.0923V15.1024C114.6 18.3463 116.237 20.3877 118.774 20.3877C120.633 20.3877 121.937 19.3872 122.412 17.5884L122.442 17.4873H124.999L124.979 17.5884C124.605 20.5797 122.129 22.6514 118.774 22.6514Z" fill="#10312B" />
                    <path d="M128.061 10.1505C127.273 10.1505 126.656 9.52393 126.656 8.7761C126.656 8.00806 127.273 7.3916 128.061 7.3916C128.849 7.3916 129.456 8.00806 129.456 8.7761C129.456 9.52393 128.849 10.1505 128.061 10.1505ZM126.798 22.3988V11.6866H129.314V22.3988H126.798Z" fill="#10312B" />
                    <path d="M135.438 22.611C133.195 22.611 132.134 21.7015 132.134 19.4984V13.6269H130.618V11.6866H132.134V8.96811H134.691V11.6866H136.681V13.6269H134.691V19.2862C134.691 20.3372 135.155 20.6707 136.075 20.6707C136.318 20.6707 136.51 20.6404 136.681 20.6302V22.52C136.388 22.5605 135.944 22.611 135.438 22.611Z" fill="#10312B" />
                    <path d="M139.845 25.956C139.36 25.956 138.854 25.8954 138.551 25.8449V23.9248C138.743 23.9652 139.036 24.0157 139.39 24.0157C140.35 24.0157 140.885 23.7428 141.209 22.8434L141.36 22.4089L137.51 11.6866H140.259L142.765 20.2563H142.836L145.352 11.6866H148L144.15 22.7626C143.331 25.1172 142.078 25.956 139.845 25.956Z" fill="#10312B" />
                  </svg>
                </Link>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleShowSidebar}
              >
                <span className="togg">
                  <span className="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M5 17H19M5 12H19M5 7H19" stroke="#FF8A00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </span>
              </button>
              <div
                className="collapse navbar-collapse marg p-0"
                id="navbarSupportedContent"
              >
                {/* <Link to="/" className="newwwwhommee">
                  <button className="homebtnns">
                    Home
                  </button>
                </Link> */}




                <ul className="navbar-nav m-auto">


                  <li class="nav-item active">

                    <HashLink to="#navbar">

                      Home</HashLink>
                  </li>
                  <li className="nav-item">

                    <HashLink to="#about"


                    >About us</HashLink>
                  </li>
                  <li className="nav-item">

                    <HashLink to="#benefits"


                    >Benefits</HashLink>
                  </li>

                  <li className="nav-item">

                    <HashLink to="#NFT"


                    >NFT</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="#roadmap"


                    >
                      Roadmap</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="#roadmap"


                    >
                      Team</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="#roadmap"


                    >
                      FAQ</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="#roadmap"


                    >
                      Whitepaper</HashLink>
                  </li>


                </ul>

                <div className="navrightbtsns">
                  <a

                    href=""
                    target="_blank"
                  >
                    <button className="twinsbtnss">
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <path d="M14.4432 1.78125H17.062L11.3407 8.32042L18.0714 17.2188H12.8013L8.67355 11.822L3.95046 17.2188H1.33005L7.44963 10.2244L0.992798 1.78125H6.39671L10.1278 6.71413L14.4432 1.78125ZM13.5241 15.6513H14.9752L5.60821 3.26642H4.05101L13.5241 15.6513Z" fill="#10312B" />
                      </svg>
                    </button>
                  </a>
                  <a

                    href=""
                    target="_blank"
                  >
                    <button className="twinsbtnss">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                        <path d="M22.3742 1.99808L19.0734 17.5645C18.8244 18.6631 18.175 18.9366 17.2521 18.419L12.2228 14.7129L9.79608 17.0469C9.52752 17.3155 9.30291 17.5401 8.78534 17.5401L9.14666 12.418L18.468 3.99515C18.8732 3.63382 18.3801 3.43362 17.8381 3.79495L6.31463 11.0508L1.35369 9.49808C0.274593 9.16116 0.255062 8.41897 1.5783 7.9014L20.9826 0.42581C21.881 0.0888964 22.6672 0.626006 22.3742 1.99808Z" fill="#10312B" />
                      </svg>
                    </button>
                  </a>
                  <button
                    class="btn button-hedaer"

                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                  >

                    Connect Wallet
                  </button>
                </div>




              </div>
            </nav>
          </div>
        </div>

      </div>


      <div className="wallet-modal">
        <div
          className="modal fade"
          id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body p-0">
                <div className="cross-icon">
                  <h3 className="modal-title text-center" id="exampleModalLabel">
                    Connect your Wallet
                  </h3>

                  <button className=" cross-img1" type="button" data-bs-dismiss="modal" aria-label="Close">



                    <img
                      src="/assets/navbar/Close_MD.svg"
                      className="img-fluid"
                      alt="close"
                    />

                  </button>
                </div>

                <div className="button-modal1 d-flex">
                  <button className="modal-button">
                    <img
                      src="/assets/navbar/metamask.svg"
                      className="img-fluid"
                      alt="MetaMask"
                    />
                    <h3>MetaMask</h3>
                    <p>Connect to your MetaMask wallet</p>
                  </button>
                  <button className="modal-button">
                    <img
                      src="/assets/navbar/walletconnect.svg"
                      className="img-fluid"
                      alt="WalletConnect"
                    />
                    <h3>WalletConnect</h3>
                    <p>Connect to your WalletConnect wallet</p>
                  </button>
                </div>
                <p className="text-center mt-3 connecting">
                  By connecting, I accept Smart city NFT{" "}
                  <span className="common connectinggg">Terms of Service</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <Offcanvas placement="top" className='mobile_sidebar' show={showsidebar} onHide={handleCloseSidebar}>
        <Offcanvas.Header closeButton>
          <div className="top-content">
            <a href="#" className="navbar-brand ">
              <Link to="/">
                {/* <img
                  src="\Logo\Logo.png"
                  alt="img"
                  width={'204px'}
                  className="img-fluid animate__animated animate__bounce"
                /> */}

                <svg xmlns="http://www.w3.org/2000/svg" width="148" height="30" viewBox="0 0 148 30" fill="none">
                  <path d="M0 29.5668C7.82462 19.315 22.2343 11.3172 22.2343 11.3172C22.2343 11.3172 37.2706 19.4512 44.4687 29.5668C37.2744 13.1778 22.2343 0 22.2343 0C22.2343 0 7.82462 12.9552 0 29.5668Z" fill="#FF8A00" />
                  <path d="M0.113922 29.4393C7.94047 25.1021 22.3483 21.718 22.3483 21.718C22.3483 21.718 37.3845 25.1605 44.5826 29.4393C37.3903 22.5046 22.3483 16.9314 22.3483 16.9314C22.3483 16.9314 7.94047 22.4117 0.113922 29.4393Z" fill="#FF8A00" />
                  <path d="M0.113922 29.4394C7.94047 27.6047 22.3483 26.1676 22.3483 26.1676C22.3483 26.1676 37.3845 27.622 44.5826 29.4329C37.3903 26.5004 22.3483 24.1406 22.3483 24.1406C22.3483 24.1406 7.94047 26.4659 0.113922 29.4394Z" fill="#FF8A00" />
                  <path d="M58.149 22.6514C54.804 22.6514 52.6009 21.0749 52.4291 18.4575L52.419 18.3059H54.9151L54.9353 18.4272C55.1273 19.6803 56.4108 20.4585 58.2399 20.4585C60.0792 20.4585 61.2313 19.6399 61.2313 18.3868V18.3766C61.2313 17.2852 60.4733 16.6991 58.5936 16.305L57.0272 15.9816C54.1572 15.3853 52.7424 14.0513 52.7424 11.8786V11.8685C52.7525 9.3016 55.0061 7.5634 58.149 7.5634C61.3323 7.5634 63.4141 9.24097 63.5657 11.7371L63.5758 11.9089H61.0797L61.0595 11.7876C60.8877 10.5143 59.7659 9.75637 58.1288 9.75637C56.4209 9.76647 55.3598 10.5648 55.3598 11.727V11.7371C55.3598 12.7679 56.1581 13.3743 57.9166 13.7381L59.4931 14.0615C62.5248 14.688 63.8487 15.9209 63.8487 18.1947V18.2048C63.8487 20.9233 61.7062 22.6514 58.149 22.6514Z" fill="#10312B" />
                  <path d="M65.5465 22.3988V11.6866H68.0628V13.4046H68.1133C68.5681 12.2323 69.6696 11.4643 71.0845 11.4643C72.5801 11.4643 73.6614 12.2525 74.0556 13.6269H74.1162C74.6316 12.2929 75.9049 11.4643 77.4511 11.4643C79.5633 11.4643 80.9478 12.8993 80.9478 15.0417V22.3988H78.4314V15.6683C78.4314 14.3444 77.7442 13.5865 76.5113 13.5865C75.2986 13.5865 74.4699 14.4859 74.4699 15.7794V22.3988H72.0243V15.5369C72.0243 14.3343 71.2967 13.5865 70.1244 13.5865C68.9117 13.5865 68.0628 14.5364 68.0628 15.8401V22.3988H65.5465Z" fill="#10312B" />
                  <path d="M86.1321 22.5807C83.9896 22.5807 82.5445 21.2568 82.5445 19.3468V19.3266C82.5445 17.4267 84.0199 16.2847 86.6171 16.123L89.4165 15.9512V15.2135C89.4165 14.1322 88.709 13.4753 87.4357 13.4753C86.2432 13.4753 85.5156 14.0311 85.3539 14.8194L85.3337 14.9103H82.9992L83.0093 14.7891C83.1609 12.8892 84.8082 11.4643 87.5267 11.4643C90.2047 11.4643 91.9328 12.8791 91.9328 15.0316V22.3988H89.4165V20.7515H89.3558C88.7394 21.8733 87.5469 22.5807 86.1321 22.5807ZM85.0507 19.2458C85.0507 20.125 85.7783 20.6606 86.8799 20.6606C88.325 20.6606 89.4165 19.7005 89.4165 18.4272V17.6187L86.9708 17.7703C85.7278 17.8511 85.0507 18.3868 85.0507 19.2356V19.2458Z" fill="#10312B" />
                  <path d="M93.9641 22.3988V11.6866H96.4804V13.536H96.531C96.8645 12.2424 97.7538 11.4643 98.9867 11.4643C99.3 11.4643 99.593 11.5148 99.785 11.5653V13.8391C99.5728 13.7583 99.1888 13.6976 98.7644 13.6976C97.3394 13.6976 96.4804 14.5971 96.4804 16.1837V22.3988H93.9641Z" fill="#10312B" />
                  <path d="M105.06 22.611C102.817 22.611 101.756 21.7015 101.756 19.4984V13.6269H100.24V11.6866H101.756V8.96811H104.312V11.6866H106.303V13.6269H104.312V19.2862C104.312 20.3372 104.777 20.6707 105.697 20.6707C105.939 20.6707 106.132 20.6404 106.303 20.6302V22.52C106.01 22.5605 105.566 22.611 105.06 22.611Z" fill="#10312B" />
                  <path d="M118.774 22.6514C114.58 22.6514 111.932 19.7611 111.932 15.1024V15.0923C111.932 10.4335 114.59 7.5634 118.774 7.5634C122.129 7.5634 124.595 9.65531 124.979 12.6264L124.989 12.7275H122.432L122.382 12.5355C121.957 10.868 120.674 9.82711 118.774 9.82711C116.227 9.82711 114.6 11.8584 114.6 15.0923V15.1024C114.6 18.3463 116.237 20.3877 118.774 20.3877C120.633 20.3877 121.937 19.3872 122.412 17.5884L122.442 17.4873H124.999L124.979 17.5884C124.605 20.5797 122.129 22.6514 118.774 22.6514Z" fill="#10312B" />
                  <path d="M128.061 10.1505C127.273 10.1505 126.656 9.52393 126.656 8.7761C126.656 8.00806 127.273 7.3916 128.061 7.3916C128.849 7.3916 129.456 8.00806 129.456 8.7761C129.456 9.52393 128.849 10.1505 128.061 10.1505ZM126.798 22.3988V11.6866H129.314V22.3988H126.798Z" fill="#10312B" />
                  <path d="M135.438 22.611C133.195 22.611 132.134 21.7015 132.134 19.4984V13.6269H130.618V11.6866H132.134V8.96811H134.691V11.6866H136.681V13.6269H134.691V19.2862C134.691 20.3372 135.155 20.6707 136.075 20.6707C136.318 20.6707 136.51 20.6404 136.681 20.6302V22.52C136.388 22.5605 135.944 22.611 135.438 22.611Z" fill="#10312B" />
                  <path d="M139.845 25.956C139.36 25.956 138.854 25.8954 138.551 25.8449V23.9248C138.743 23.9652 139.036 24.0157 139.39 24.0157C140.35 24.0157 140.885 23.7428 141.209 22.8434L141.36 22.4089L137.51 11.6866H140.259L142.765 20.2563H142.836L145.352 11.6866H148L144.15 22.7626C143.331 25.1172 142.078 25.956 139.845 25.956Z" fill="#10312B" />
                </svg>
              </Link>
            </a>

          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <ul className="navbar-nav m-auto">
            {/* <button className="homebtnns">
                  Home
                </button> */}
            <li className="nav-item  ">
              <Link to="#navbar"

              >Home</Link>
            </li>
            <li className="nav-item  ">
              <Link to="#navbar"

              >About us</Link>
            </li>
            <li className="nav-item  ">
              <Link to="#"> Benefits</Link>
            </li>
            <li className="nav-item  ">

              <Link to="#about"


              >NFT</Link>
            </li>
            <li className="nav-item  ">
              <Link to="#roadmap"


              >
                Roadmap</Link>
            </li>
            <li className="nav-item  ">
              <Link to="#roadmap"


              >
                Team</Link>
            </li>
            <li className="nav-item  ">
              <Link to="#roadmap"


              >
                FAQ</Link>
            </li>
            <li className="nav-item  ">
              <Link to="#roadmap"


              >
                Whitepaper</Link>
            </li>


          </ul>


        </Offcanvas.Body>
      </Offcanvas>

    </section>
  );
};

export default Navbarmint;
