import '../../App.scss';
import React, { useState, useEffect } from 'react';
import Banner from './main-banner/Banner.js';
import Affiliateprogram from './Affiliateprogram/Affiliateprogram.js';
import Navbar from './header/Navbar.js';
import Benefits from './Benefits/Benefits.js';
import Information from './Information/Information.js';
import Buynow from './buynow/Buynow.js';
import Faqs from './faqs/Faqs.js';
import Ourteam from './ourteam/Ourteam.js';
import Roadmap from './roadmap/Roadmap.js';
import Smartcity from './Smartcity/Smartcity.js';
import Ourteamsec from './ourteamsec/Ourteamsec.js';
import Loader from '../../Assests/loader.svg'; // Ensure correct path
import axios from 'axios';

function Landing() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a network request or other async operation
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds delay for demonstration
    return () => clearTimeout(timer);
  }, []);

  const [metadataArray, setMetadataArray] = useState([]);

  const fetchMetadata = async (index) => {
    try {
      // Replace this with your Pinata gateway URL
      const response = await axios.get(`https://ipfs.io/ipfs/QmdYtbbsEYXr1TMKULevrx8Q4LACQMLokBVabADiUT2HYK/${index}`);
      console.log(response, `res metadata for index ${index}`);

      // Add the new metadata to the array
      setMetadataArray((prevMetadata) => [...prevMetadata, response.data]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchAllMetadata = async () => {
      for (let i = 1; i <= 10; i++) {
        await fetchMetadata(i); // You can replace the 'i' with different identifiers if needed
      }
      setLoading(false);
    };

    fetchAllMetadata();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader">
          <img src={Loader} alt="Loading..." />
        </div>
      ) : (
        <>
          <Banner />
          <Affiliateprogram />
          <Benefits />
          <Ourteam metadataArray={metadataArray} />
          <Smartcity />
          <Information />
          <Roadmap />
          <Buynow />
          <Ourteamsec />
          <Faqs />
        </>
      )}
    </>
  );
}

export default Landing;
