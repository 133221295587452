import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";

const Navbar = () => {

  const { login, logout } = useAuth();
  const { account } = useWeb3React();

  const [isModalOpen, setModalOpen] = useState(false);
  const [showsidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);
  console.log(showsidebar, "showsidebar");

  const handleClose = () => {
    setModalOpen(false);
    handleCloseSidebar();
  };

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  
  const connectMetaMask1 = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      handleClose();
    }
  };

  const trustWallet = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
      handleClose();
    } else {

      try {
        handleClose();
        login("walletconnect");
        localStorage.setItem('connectorId', 'walletconnect');
        localStorage.setItem("flag", "true");
      } catch (err) {
        console.log("eerr", err);
      }
    }
  };

  return (
    <section className="main-navbar-mobile main-navbar">
      <div className="container-fluid  ">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12  m-auto    nav_boder ">
            <nav className="navbar  navbar-expand-xl ">
              <a href="#" className="navbar-brand animate__flash" >
                <Link to="/">
                  {/* <img
                    src="\Logo\Logo.png"
                    alt="img"
                    width={'204px'}
                    className="img-fluid animate__animated animate__bounce"
                  /> */}


                  <svg xmlns="http://www.w3.org/2000/svg" width="148" height="30" viewBox="0 0 148 30" fill="none">
                    <path d="M0 29.5668C7.82462 19.315 22.2343 11.3172 22.2343 11.3172C22.2343 11.3172 37.2706 19.4512 44.4687 29.5668C37.2744 13.1778 22.2343 0 22.2343 0C22.2343 0 7.82462 12.9552 0 29.5668Z" fill="#FF8A00" />
                    <path d="M0.114258 29.4395C7.94081 25.1024 22.3486 21.7183 22.3486 21.7183C22.3486 21.7183 37.3848 25.1607 44.5829 29.4395C37.3906 22.5049 22.3486 16.9316 22.3486 16.9316C22.3486 16.9316 7.94081 22.4119 0.114258 29.4395Z" fill="#FF8A00" />
                    <path d="M0.114258 29.4394C7.94081 27.6047 22.3486 26.1676 22.3486 26.1676C22.3486 26.1676 37.3848 27.622 44.5829 29.4329C37.3906 26.5004 22.3486 24.1406 22.3486 24.1406C22.3486 24.1406 7.94081 26.4659 0.114258 29.4394Z" fill="#FF8A00" />
                    <path d="M58.149 22.6514C54.8039 22.6514 52.6009 21.0749 52.4291 18.4575L52.4189 18.3059H54.9151L54.9353 18.4272C55.1273 19.6803 56.4108 20.4585 58.2399 20.4585C60.0792 20.4585 61.2312 19.6399 61.2312 18.3868V18.3766C61.2312 17.2852 60.4733 16.6991 58.5936 16.305L57.0272 15.9816C54.1571 15.3853 52.7423 14.0513 52.7423 11.8786V11.8685C52.7524 9.3016 55.006 7.5634 58.149 7.5634C61.3323 7.5634 63.4141 9.24097 63.5657 11.7371L63.5758 11.9089H61.0796L61.0594 11.7876C60.8876 10.5143 59.7659 9.75637 58.1287 9.75637C56.4209 9.76647 55.3597 10.5648 55.3597 11.727V11.7371C55.3597 12.7679 56.1581 13.3743 57.9165 13.7381L59.493 14.0615C62.5248 14.688 63.8486 15.9209 63.8486 18.1947V18.2048C63.8486 20.9233 61.7062 22.6514 58.149 22.6514Z" fill="white" />
                    <path d="M65.5464 22.3988V11.6866H68.0628V13.4046H68.1133C68.5681 12.2323 69.6696 11.4643 71.0844 11.4643C72.5801 11.4643 73.6614 12.2525 74.0555 13.6269H74.1162C74.6316 12.2929 75.9049 11.4643 77.4511 11.4643C79.5632 11.4643 80.9477 12.8993 80.9477 15.0417V22.3988H78.4314V15.6683C78.4314 14.3444 77.7442 13.5865 76.5113 13.5865C75.2986 13.5865 74.4699 14.4859 74.4699 15.7794V22.3988H72.0243V15.5369C72.0243 14.3343 71.2967 13.5865 70.1244 13.5865C68.9117 13.5865 68.0628 14.5364 68.0628 15.8401V22.3988H65.5464Z" fill="white" />
                    <path d="M86.132 22.5807C83.9896 22.5807 82.5444 21.2568 82.5444 19.3468V19.3266C82.5444 17.4267 84.0199 16.2847 86.6171 16.123L89.4164 15.9512V15.2135C89.4164 14.1322 88.709 13.4753 87.4357 13.4753C86.2432 13.4753 85.5156 14.0311 85.3539 14.8194L85.3337 14.9103H82.9992L83.0093 14.7891C83.1609 12.8892 84.8082 11.4643 87.5266 11.4643C90.2047 11.4643 91.9328 12.8791 91.9328 15.0316V22.3988H89.4164V20.7515H89.3558C88.7393 21.8733 87.5468 22.5807 86.132 22.5807ZM85.0507 19.2458C85.0507 20.125 85.7783 20.6606 86.8799 20.6606C88.325 20.6606 89.4164 19.7005 89.4164 18.4272V17.6187L86.9708 17.7703C85.7278 17.8511 85.0507 18.3868 85.0507 19.2356V19.2458Z" fill="white" />
                    <path d="M93.964 22.3988V11.6866H96.4804V13.536H96.5309C96.8644 12.2424 97.7537 11.4643 98.9866 11.4643C99.2999 11.4643 99.593 11.5148 99.785 11.5653V13.8391C99.5728 13.7583 99.1888 13.6976 98.7643 13.6976C97.3394 13.6976 96.4804 14.5971 96.4804 16.1837V22.3988H93.964Z" fill="white" />
                    <path d="M105.06 22.611C102.817 22.611 101.756 21.7015 101.756 19.4984V13.6269H100.24V11.6866H101.756V8.96811H104.312V11.6866H106.303V13.6269H104.312V19.2862C104.312 20.3372 104.777 20.6707 105.697 20.6707C105.939 20.6707 106.131 20.6404 106.303 20.6302V22.52C106.01 22.5605 105.566 22.611 105.06 22.611Z" fill="white" />
                    <path d="M118.774 22.6514C114.58 22.6514 111.932 19.7611 111.932 15.1024V15.0923C111.932 10.4335 114.59 7.5634 118.774 7.5634C122.129 7.5634 124.595 9.65531 124.979 12.6264L124.989 12.7275H122.432L122.382 12.5355C121.957 10.868 120.674 9.82711 118.774 9.82711C116.227 9.82711 114.6 11.8584 114.6 15.0923V15.1024C114.6 18.3463 116.237 20.3877 118.774 20.3877C120.633 20.3877 121.937 19.3872 122.412 17.5884L122.442 17.4873H124.999L124.979 17.5884C124.605 20.5797 122.129 22.6514 118.774 22.6514Z" fill="white" />
                    <path d="M128.061 10.1505C127.273 10.1505 126.656 9.52393 126.656 8.7761C126.656 8.00806 127.273 7.3916 128.061 7.3916C128.849 7.3916 129.456 8.00806 129.456 8.7761C129.456 9.52393 128.849 10.1505 128.061 10.1505ZM126.798 22.3988V11.6866H129.314V22.3988H126.798Z" fill="white" />
                    <path d="M135.438 22.611C133.195 22.611 132.134 21.7015 132.134 19.4984V13.6269H130.618V11.6866H132.134V8.96811H134.691V11.6866H136.681V13.6269H134.691V19.2862C134.691 20.3372 135.155 20.6707 136.075 20.6707C136.318 20.6707 136.51 20.6404 136.681 20.6302V22.52C136.388 22.5605 135.944 22.611 135.438 22.611Z" fill="white" />
                    <path d="M139.845 25.956C139.359 25.956 138.854 25.8954 138.551 25.8449V23.9248C138.743 23.9652 139.036 24.0157 139.39 24.0157C140.35 24.0157 140.885 23.7428 141.209 22.8434L141.36 22.4089L137.51 11.6866H140.259L142.765 20.2563H142.836L145.352 11.6866H148L144.15 22.7626C143.331 25.1172 142.078 25.956 139.845 25.956Z" fill="white" />
                  </svg>
                </Link>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleShowSidebar}
              >
                <span className="togg">
                  <span className="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M5 17H19M5 12H19M5 7H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </span>
              </button>
              <div
                className="collapse navbar-collapse marg p-0"
                id="navbarSupportedContent"
              >
                {/* <Link to="/" className="newwwwhommee">
                  <button className="homebtnns">
                    Home
                  </button>
                </Link> */}




                <ul className="navbar-nav m-auto">


                  <li class="nav-item active">

                    <HashLink to="#navbar"
                      style={
                        isActive("")
                          ? {
                            color: "#FF8A00",
                          }
                          : {}}
                    >Home</HashLink>
                  </li>
                  <li className="nav-item">

                    <HashLink to="#about"
                      style={
                        isActive("#about")
                          ? {
                            color: "#FF8A00",
                          }
                          : {}}

                    >About us</HashLink>
                  </li>
                  <li className="nav-item">

                    <HashLink to="#benefits"
                      style={
                        isActive("#benefits")
                          ? {
                            color: "#FF8A00",
                          }
                          : {}}

                    >Benefits</HashLink>
                  </li>

                  <li className="nav-item">

                    <HashLink to="#information"
                      style={
                        isActive("#information")
                          ? {
                            color: "#FF8A00",
                          }
                          : {}}

                    >NFT</HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink to="#roadmap"
                      style={
                        isActive("#roadmap")
                          ? {
                            color: "#FF8A00",
                          }
                          : {}}

                    >
                      Roadmap</HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink to="#ourteamsec"
                      style={
                        isActive("#ourteamsec")
                          ? {
                            color: "#FF8A00",
                          }
                          : {}}

                    >
                      Team</HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink to="#faqs"
                      style={
                        isActive("#faqs")
                          ? {
                            color: "#FF8A00",
                          }
                          : {}}

                    >
                      FAQ</HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink to="#"
                      style={
                        isActive("#")
                          ? {
                            color: "#FF8A00",
                          }
                          : {}}

                    >
                      Whitepaper</HashLink>
                  </li>


                </ul>

                <div className="navrightbtsns">
                  <a

                    href=""
                    target="_blank"
                  >
                    <button className="twinsbtnss">
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <path d="M14.4436 1.78125H17.0624L11.341 8.32042L18.0718 17.2188H12.8017L8.67391 11.822L3.95083 17.2188H1.33041L7.45 10.2244L0.993164 1.78125H6.39708L10.1282 6.71413L14.4436 1.78125ZM13.5245 15.6513H14.9756L5.60858 3.26642H4.05137L13.5245 15.6513Z" fill="white" />
                      </svg>
                    </button>
                  </a>
                  <a

                    href=""
                    target="_blank"
                  >
                    <button className="twinsbtnss">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                        <path d="M22.3742 1.99808L19.0734 17.5645C18.8244 18.6631 18.175 18.9366 17.2521 18.419L12.2228 14.7129L9.79608 17.0469C9.52752 17.3155 9.30291 17.5401 8.78534 17.5401L9.14666 12.418L18.468 3.99515C18.8732 3.63382 18.3801 3.43362 17.8381 3.79495L6.31463 11.0508L1.35369 9.49808C0.274593 9.16116 0.255062 8.41897 1.5783 7.9014L20.9826 0.42581C21.881 0.0888964 22.6672 0.626006 22.3742 1.99808Z" fill="white" />
                      </svg>
                    </button>
                  </a>
                  <button
                    class="btn button-hedaer"

                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                  >

                    Connect Wallet
                  </button>
                </div>




              </div>
            </nav>
          </div>
        </div>

      </div>

      <div className={`wallet-modal ${isModalOpen ? 'show' : 'hide'}`}>
        <div
          className="modal fade show"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!isModalOpen}
          style={{ display: isModalOpen ? 'block' : 'none' }}
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div className="cross-icon">
                  <h3 class="modal-title text-center" id="exampleModalLabel">
                    Connect your Wallet
                  </h3>
                  <button className=" cross-img1" type="button" data-bs-dismiss="modal" aria-label="Close">
                    <img
                      src="/assets/navbar/Close_MD.svg"
                      className="img-fluid"
                      alt="close"
                    />

                  </button>
                </div>

                <div className="button-modal1 d-flex">
                  <button className="modal-button" onClick={connectMetaMask1}>
                    <img
                      src="\assets\navbar\metamask.svg"
                      className="img-fluid"
                    />
                    <h3 className=""> MetaMask</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                  <button className="modal-button" onClick={trustWallet}>
                    <img
                      src="\assets\navbar\walletconnect.svg"
                      className="img-fluid"
                    />
                    <h3 className="">WalletConnect</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                </div>
                <p className="text-center mt-3  connecting">
                  By connecting, I accept Smart city NFT{" "}
                  <span className="common connectinggg">Terms of Service </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Offcanvas placement="top" className='mobile_sidebar' show={showsidebar} onHide={handleCloseSidebar}>
        <Offcanvas.Header closeButton>
          <div className="top-content">
            <a href="#" className="navbar-brand " onClick={handleCloseSidebar}>
              <Link to="/">
                {/* <img
                  src="\Logo\Logo.png"
                  alt="img"
                  width={'204px'}
                  className="img-fluid animate__animated animate__bounce"
                /> */}

                <svg xmlns="http://www.w3.org/2000/svg" width="113" height="23" viewBox="0 0 113 23" fill="none">
                  <path d="M0 22.5746C5.9742 14.7473 16.9762 8.6408 16.9762 8.6408C16.9762 8.6408 28.4566 14.8512 33.9524 22.5746C28.4595 10.0614 16.9762 0 16.9762 0C16.9762 0 5.9742 9.89146 0 22.5746Z" fill="#FF8A00" />
                  <path d="M0.0869141 22.4772C6.06259 19.1657 17.0631 16.5819 17.0631 16.5819C17.0631 16.5819 28.5435 19.2103 34.0393 22.4772C28.5479 17.1825 17.0631 12.9272 17.0631 12.9272C17.0631 12.9272 6.06259 17.1115 0.0869141 22.4772Z" fill="#FF8A00" />
                  <path d="M0.0869141 22.4773C6.06259 21.0765 17.0631 19.9793 17.0631 19.9793C17.0631 19.9793 28.5435 21.0897 34.0393 22.4724C28.5479 20.2334 17.0631 18.4316 17.0631 18.4316C17.0631 18.4316 6.06259 20.207 0.0869141 22.4773Z" fill="#FF8A00" />
                  <path d="M44.3975 17.2946C41.8435 17.2946 40.1615 16.0909 40.0303 14.0925L40.0226 13.9768H41.9284L41.9439 14.0694C42.0905 15.0261 43.0704 15.6203 44.467 15.6203C45.8713 15.6203 46.7509 14.9953 46.7509 14.0385V14.0308C46.7509 13.1975 46.1722 12.7499 44.737 12.449L43.5411 12.2021C41.3497 11.7469 40.2695 10.7284 40.2695 9.06943V9.06172C40.2772 7.10187 41.9979 5.77473 44.3975 5.77473C46.828 5.77473 48.4175 7.05557 48.5333 8.96141L48.541 9.09258H46.6351L46.6197 8.99999C46.4885 8.02778 45.6321 7.44909 44.3821 7.44909C43.0781 7.4568 42.2679 8.06636 42.2679 8.95369V8.96141C42.2679 9.74844 42.8775 10.2114 44.2201 10.4892L45.4237 10.7361C47.7385 11.2145 48.7493 12.1558 48.7493 13.8919V13.8996C48.7493 15.9752 47.1135 17.2946 44.3975 17.2946Z" fill="#10312B" />
                  <path d="M50.0456 17.1017V8.92283H51.9669V10.2345H52.0054C52.3527 9.33949 53.1937 8.75308 54.2739 8.75308C55.4159 8.75308 56.2415 9.35492 56.5424 10.4043H56.5887C56.9822 9.38579 57.9544 8.75308 59.135 8.75308C60.7476 8.75308 61.8047 9.84874 61.8047 11.4845V17.1017H59.8834V11.9629C59.8834 10.9521 59.3587 10.3734 58.4174 10.3734C57.4915 10.3734 56.8588 11.0601 56.8588 12.0478V17.1017H54.9915V11.8626C54.9915 10.9444 54.436 10.3734 53.5409 10.3734C52.615 10.3734 51.9669 11.0987 51.9669 12.0941V17.1017H50.0456Z" fill="#10312B" />
                  <path d="M65.763 17.2406C64.1272 17.2406 63.0238 16.2298 63.0238 14.7715V14.7561C63.0238 13.3055 64.1503 12.4336 66.1333 12.3101L68.2707 12.179V11.6157C68.2707 10.7901 67.7305 10.2886 66.7583 10.2886C65.8478 10.2886 65.2923 10.7129 65.1688 11.3148L65.1534 11.3842H63.371L63.3787 11.2916C63.4945 9.84103 64.7522 8.75308 66.8278 8.75308C68.8725 8.75308 70.1919 9.83331 70.1919 11.4768V17.1017H68.2707V15.844H68.2244C67.7537 16.7005 66.8432 17.2406 65.763 17.2406ZM64.9374 14.6944C64.9374 15.3656 65.4929 15.7746 66.334 15.7746C67.4373 15.7746 68.2707 15.0416 68.2707 14.0694V13.4521L66.4034 13.5678C65.4543 13.6296 64.9374 14.0385 64.9374 14.6866V14.6944Z" fill="#10312B" />
                  <path d="M71.7428 17.1017V8.92283H73.6641V10.3348H73.7027C73.9573 9.34721 74.6363 8.75308 75.5777 8.75308C75.8168 8.75308 76.0406 8.79166 76.1872 8.83024V10.5663C76.0252 10.5046 75.732 10.4583 75.4079 10.4583C74.32 10.4583 73.6641 11.145 73.6641 12.3564V17.1017H71.7428Z" fill="#10312B" />
                  <path d="M80.2149 17.2638C78.502 17.2638 77.6918 16.5693 77.6918 14.8873V10.4043H76.5344V8.92283H77.6918V6.84724H79.644V8.92283H81.164V10.4043H79.644V14.7252C79.644 15.5277 79.9989 15.7823 80.701 15.7823C80.8862 15.7823 81.0328 15.7592 81.164 15.7514V17.1943C80.9402 17.2252 80.6007 17.2638 80.2149 17.2638Z" fill="#10312B" />
                  <path d="M90.6855 17.2946C87.4834 17.2946 85.4618 15.0879 85.4618 11.5308V11.5231C85.4618 7.96605 87.4911 5.77473 90.6855 5.77473C93.2472 5.77473 95.1299 7.37193 95.4231 9.64041L95.4308 9.71757H93.4786L93.4401 9.57097C93.116 8.29784 92.1361 7.5031 90.6855 7.5031C88.7411 7.5031 87.4988 9.054 87.4988 11.5231V11.5308C87.4988 14.0076 88.7488 15.5663 90.6855 15.5663C92.1052 15.5663 93.1006 14.8024 93.4632 13.4289L93.4864 13.3518H95.4385L95.4231 13.4289C95.1376 15.7129 93.2472 17.2946 90.6855 17.2946Z" fill="#10312B" />
                  <path d="M97.7764 7.75001C97.1746 7.75001 96.7039 7.27162 96.7039 6.70064C96.7039 6.11423 97.1746 5.64355 97.7764 5.64355C98.3783 5.64355 98.8412 6.11423 98.8412 6.70064C98.8412 7.27162 98.3783 7.75001 97.7764 7.75001ZM96.8119 17.1017V8.92283H98.7332V17.1017H96.8119Z" fill="#10312B" />
                  <path d="M103.409 17.2638C101.696 17.2638 100.886 16.5693 100.886 14.8873V10.4043H99.7286V8.92283H100.886V6.84724H102.838V8.92283H104.358V10.4043H102.838V14.7252C102.838 15.5277 103.193 15.7823 103.895 15.7823C104.08 15.7823 104.227 15.7592 104.358 15.7514V17.1943C104.134 17.2252 103.795 17.2638 103.409 17.2638Z" fill="#10312B" />
                  <path d="M106.773 19.8177C106.403 19.8177 106.017 19.7714 105.786 19.7329V18.2668C105.932 18.2977 106.156 18.3363 106.426 18.3363C107.159 18.3363 107.568 18.128 107.815 17.4412L107.931 17.1094L104.991 8.92283H107.09L109.003 15.466H109.057L110.978 8.92283H113L110.06 17.3795C109.435 19.1773 108.478 19.8177 106.773 19.8177Z" fill="#10312B" />
                </svg>
              </Link>
            </a>

          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <ul className="navbar-nav m-auto">
            {/* <button className="homebtnns">
                  Home
                </button> */}

            <li class="nav-item active" >

              <HashLink to="#navbar" onClick={handleCloseSidebar}
                style={
                  isActive("")
                    ? {
                      color: "#FF8A00",
                    }
                    : {}}
              >Home</HashLink>
            </li>
            <li className="nav-item">

              <HashLink to="#about" onClick={handleCloseSidebar}
                style={
                  isActive("#about")
                    ? {
                      color: "#FF8A00",
                    }
                    : {}}

              >About us</HashLink>
            </li>
            <li className="nav-item">

              <HashLink to="#benefits" onClick={handleCloseSidebar}
                style={
                  isActive("#benefits")
                    ? {
                      color: "#FF8A00",
                    }
                    : {}}

              >Benefits</HashLink>
            </li>

            <li className="nav-item">

              <HashLink to="#information" onClick={handleCloseSidebar}
                style={
                  isActive("#information")
                    ? {
                      color: "#FF8A00",
                    }
                    : {}}

              >NFT</HashLink>
            </li>
            <li className="nav-item ">
              <HashLink to="#roadmap" onClick={handleCloseSidebar}
                style={
                  isActive("#roadmap")
                    ? {
                      color: "#FF8A00",
                    }
                    : {}}

              >
                Roadmap</HashLink>
            </li>
            <li className="nav-item ">
              <HashLink to="#ourteamsec" onClick={handleCloseSidebar}
                style={
                  isActive("#ourteamsec")
                    ? {
                      color: "#FF8A00",
                    }
                    : {}}

              >
                Team</HashLink>
            </li>
            <li className="nav-item ">
              <HashLink to="#faqs" onClick={handleCloseSidebar}
                style={
                  isActive("#faqs")
                    ? {
                      color: "#FF8A00",
                    }
                    : {}}

              >
                FAQ</HashLink>
            </li>
            <li className="nav-item ">
              <HashLink to="#"
                style={
                  isActive("#")
                    ? {
                      color: "#FF8A00",
                    }
                    : {}}
              >
                Whitepaper</HashLink>
            </li>
            {/* <button data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleCloseSidebar}>
              Connect Wallet
            </button> */}
            <button
              onClick={() => { setModalOpen(true); handleCloseSidebar(); }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Connect Wallet
            </button>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
};

export default Navbar;
