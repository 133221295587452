import React from "react";
import "./roadmap.scss";
import Carousel from 'react-bootstrap/Carousel';
import 'react-image-gallery/styles/css/image-gallery.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



const Roadmap = () => {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 4, // Default number of slides for desktop
    slidesToScroll: 1,
    
  
    responsive: [
      {
        breakpoint: 1024, // Screens smaller than 1024px
        settings: {
          slidesToShow: 3, // Adjust for tablets
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768, // Screens smaller than 768px (mobile)
        settings: {
          slidesToShow: 2, // Show 2 cards on smaller screens
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 450, // Screens smaller than 450px (mobile)
        settings: {
          slidesToShow: 1, // Show 1 full card
          slidesToScroll: 1
        }
      }
    ]
};  
  

  return (
    <>
      <section className="Roadmaps" id="roadmap">

        <div className="custom-container">
          <div className="myyynewdiv">
            <div className="toperparxraa">
              <p className="commonhessdsading ">
                Roadmap
              </p>
              <h2 className="dsdsds">Comprehensive Strategic Roadmap for Future Growth and Innovation</h2>
              <p className="seccssdsparaa">
                Explore our strategic journey as we chart the course for innovation and growth, outlining key milestones and future goals that drive our mission forward.
              </p>
            </div>
            {/* <div className="card_mains">
          </div> */}
            <div className="maincarddiv p-0">
              <Slider {...settings}>
                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\sofas1.svg" />
                    </div>
                    <div className="right_side">
                      <h2 className="phase111">Phase 01</h2>
                      <p className="datttrers">October 2024</p>
                      <ul className="fantomms">
                        <li className="ioucserd">Whitepaper Launch </li>
                        <li className="ioucserd">Whitelist Registrations </li>
                        {/* <li className="ioucserd">Marketing campaign</li> */}


                      </ul>
                    </div>
                  </div>
                </div>
                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\sofas2.svg" />
                    </div>
                    <div className="right_side">
                      <h2 className="phase111">Phase 02</h2>
                      <p className="datttrers">November 2024</p>
                      <ul className="fantomms">
                        <li className="ioucserd">NFT Minting Anouncement </li>
                        <li className="ioucserd">Marketing Campaign </li>
                        {/* <li className="ioucserd">Marketing campaign</li> */}


                      </ul>
                    </div>
                  </div>
                </div>
                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\sofas3.svg" />
                    </div>
                    <div className="right_side">
                      <h2 className="phase111">Phase 03</h2>
                      <p className="datttrers">December 2024</p>
                      <ul className="fantomms">
                        <li className="ioucserd">NFT Presale Launch </li>
                        <li className="ioucserd">NFT Minting Launch </li>
                        {/* <li className="ioucserd">Marketing campaign</li> */}


                      </ul>
                    </div>
                  </div>
                </div>
                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\sofas4.svg" />
                    </div>
                    <div className="right_side">
                      <h2 className="phase111">Phase 04</h2>
                      <p className="datttrers">June 2025</p>
                      <ul className="fantomms">
                        <li className="ioucserd">Profit System Dashboard </li>
                        {/* <li className="ioucserd">First press releases </li>
                        <li className="ioucserd">Marketing campaign</li> */}


                      </ul>
                    </div>
                  </div>
                </div>
                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\sofas1.svg" />
                    </div>
                    <div className="right_side">
                      <h2 className="phase111">Phase 05</h2>
                      <p className="datttrers">December 2025</p>
                      <ul className="fantomms">
                        <li className="ioucserd">Profit Withdrawl </li>
                        <li className="ioucserd">New properties </li>
                        {/* <li className="ioucserd">Marketing campaign</li> */}


                      </ul>
                    </div>
                  </div>
                </div>
                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\sofas1.svg" />
                    </div>
                    <div className="right_side">
                      <h2 className="phase111">Phase 06</h2>
                      <p className="datttrers">2026</p>
                      <ul className="fantomms">
                        <li className="ioucserd">Nft marketplace </li>
                        {/* <li className="ioucserd">New properties </li> */}
                        {/* <li className="ioucserd">Marketing campaign</li> */}


                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="maincarddiv">
        <div className="inner_banner">
              <div className="inner_part">
                <img className="imgback" src="/assets/roadmap/roadmap.png" />
              </div>
              <div className="right_side">
                <h2 className="phase111">Phase 06</h2>
                <p className="datttrers">March 2024</p>
                <ul className="fantomms">
                  <li className="ioucserd">ICO and community pre-sale </li>
                  <li className="ioucserd">First press releases </li>
                  <li className="ioucserd">Marketing campaign</li>


                </ul>
              </div>
            </div>
        </div>
        <div className="maincarddiv">
        <div className="inner_banner">
              <div className="inner_part">
                <img className="imgback" src="/assets/roadmap/roadmap.png" />
              </div>
              <div className="right_side">
                <h2 className="phase111">Phase 07</h2>
                <p className="datttrers">March 2024</p>
                <ul className="fantomms">
                  <li className="ioucserd">ICO and community pre-sale </li>
                  <li className="ioucserd">First press releases </li>
                  <li className="ioucserd">Marketing campaign</li>


                </ul>
              </div>
            </div>
        </div>
        <div className="maincarddiv">
        <div className="inner_banner">
              <div className="inner_part">
                <img className="imgback" src="/assets/roadmap/roadmap.png" />
              </div>
              <div className="right_side">
                <h2 className="phase111">Phase 08</h2>
                <p className="datttrers">March 2024</p>
                <ul className="fantomms">
                  <li className="ioucserd">ICO and community pre-sale </li>
                  <li className="ioucserd">First press releases </li>
                  <li className="ioucserd">Marketing campaign</li>


                </ul>
              </div>
            </div>
        </div>
        <div className="maincarddiv">
        <div className="inner_banner">
              <div className="inner_part">
                <img className="imgback" src="/assets/roadmap/roadmap.png" />
              </div>
              <div className="right_side">
                <h2 className="phase111">Phase 09</h2>
                <p className="datttrers">March 2024</p>
                <ul className="fantomms">
                  <li className="ioucserd">ICO and community pre-sale </li>
                  <li className="ioucserd">First press releases </li>
                  <li className="ioucserd">Marketing campaign</li>


                </ul>
              </div>
            </div>
        </div>
        <div className="maincarddiv">
        <div className="inner_banner">
              <div className="inner_part">
                <img className="imgback" src="/assets/roadmap/roadmap.png" />
              </div>
              <div className="right_side">
                <h2 className="phase111">Phase 10</h2>
                <p className="datttrers">March 2024</p>
                <ul className="fantomms">
                  <li className="ioucserd">ICO and community pre-sale </li>
                  <li className="ioucserd">First press releases </li>
                  <li className="ioucserd">Marketing campaign</li>
                </ul>
              </div>
            </div>
        </div> */}
              </Slider>
            </div>

          </div>
        </div>

      </section>

    </>
  );
};

export default Roadmap;
