import React, { useEffect } from "react";
import './affiliateprogram.scss'
import AOS from "aos";
import "aos/dist/aos.css";

const Affiliateprogram = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <section className=" affiliateprogram" id="about">
                <div className="custom-container ">
                    <div className="mainouterdivabout">
                        <div className="rightfstt  aboutvvccc "  >
                            <p className="aboutvv m-0 ">
                                <span className="forlineonle"><svg width="46" height="2" viewBox="0 0 46 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="46" height="2" fill="#FF8A00" />
                                </svg>

                                </span>About Smart City
                            </p>

                            <h3 className="mypara m-0">
                                Welcome to Smart City, a beacon of real estate innovation and fertile ground for NFT real estate.
                            </h3>
                            <p className="myparasecsde">
                                Smart City is a collection of several real estate sites consisting of ready-to-move-in hotels, built by internationally recognised construction companies.
                            </p>
                            <h5 className="myparahead">What We Offer :</h5>
                            <ul class="b">
                                <li className="douuttares">505+ rooms and 131 private residences across multiple locations.</li>
                                <li className="douuttares py-4">Futuristic Jenga-like structures with angular, geometric designs.</li>
                                <li className="douuttares">Property sizes from 250 to 7,126 square metres.</li>
                                <li className="douuttares py-4">Properties include 4-5 bedroom flats, 2-4 Sky Courts</li>
                                <li className="douuttares">4 Penthouses, and 2-4 Garden Suites.</li>
                            </ul>


                        </div>


                        <div className="aboutusimgg " data-aos="fade-right"
                            data-aos-easing="linear"
                            data-aos-duration="1000">
                            <img
                                src="\assets\secbaner\secbanerleft.png"
                                alt="img"
                                className="img-fluid left-img  "
                            />
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Affiliateprogram
