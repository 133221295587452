import React, { useEffect } from "react";

import './ourteamsec.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Ourteamsec = () => {
    const settings = {
        // dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        autoplay: false,
        slidesToShow: 4, // Default number of slides for desktop
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024, // Screens smaller than 1024px
            settings: {
              slidesToShow: 3, // Adjust for tablets
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 768, // Screens smaller than 768px (mobile)
            settings: {
              slidesToShow: 2, // Show 2 cards on smaller screens
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450, // Screens smaller than 450px (mobile)
            settings: {
              slidesToShow: 1, // Show 1 full card
              slidesToScroll: 1
            }
          }
        ]
    };  
      


    return (
        <>
            <section className="ourteam" id="ourteamsec">
                <div className="custom-container p-0">
                    <div className="mainouterdivproperties">
                        <div className="rightfstt_aboutvvccc "  >
                            <p className="aboutdfdvv ">
                                Our Team
                            </p>

                            <h3 className="myparxxa">
                                Meet Our Team of Experts
                            </h3>
                            <p className="myparasefrecsde">
                                Meet the dedicated professionals driving our vision forward, each bringing unique expertise and passion to deliver exceptional results and foster innovation.
                            </p>



                        </div>
                        {/* <button className="nnmoreproperties">
                             
                                    See All Properties
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M3.75 9H14.25M14.25 9L9.75 4.5M14.25 9L9.75 13.5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>

                                </button> */}

                    </div>

                    <div className="ourteammainouter_divseddc myyynewdiv">
                        <Slider {...settings}>
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\ourteamsec\newtem1.png" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Jaydon Herwitz</h1>
                                        <p>Founder & CEO</p>
                                    </div>
                                </div>
                            </div>
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\ourteamsec\newtem2.png" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Jordan Lee</h1>
                                        <p>Software Engineer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\ourteamsec\newtem3.png" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Taylor Morgan</h1>
                                        <p>Marketing Strategist</p>
                                    </div>
                                </div>
                            </div>
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\ourteamsec\newtem4.png" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Jamie Patel</h1>
                                        <p>Project Manager</p>
                                    </div>
                                </div>
                            </div>
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\ourteamsec\newtem1.png" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Jaydon Herwitz</h1>
                                        <p>Founder & CEO</p>
                                    </div>
                                </div>
                            </div>
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\ourteamsec\newtem2.png" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Jordan Lee</h1>
                                        <p>Software Engineer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\ourteamsec\newtem3.png" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Taylor Morgan</h1>
                                        <p>Marketing Strategist</p>
                                    </div>
                                </div>
                            </div>
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\ourteamsec\newtem4.png" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Jamie Patel</h1>
                                        <p>Project Manager</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>

                </div>
            </section>
        </>
    );
};

export default Ourteamsec;
