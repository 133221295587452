import React, { useEffect } from "react";
import { Carousel } from 'react-bootstrap';
import './buynow.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";

const Buynow = () => {


    return (
        <>
            <section className="buynow_main" id="about">
                <div className="container-fluid">


                    <div className="mainbanner_infoss">
                        <div className="row">
                            <div className="col-xl-7 col-sm-12 m-auto">

                                <div className="newinoeart">
                                    <h2 className="apple">
                                        Be one of the first to enter Smart City
                                    </h2>
                                    <Link to="/mint">

                                    <button className="buynowbtn">
                                        Buy Now
                                    </button>
                                    </Link>
                          

                                </div>




                            </div>
                        </div>


                    </div>



                </div>
            </section>
        </>
    );
};

export default Buynow;
