import React, { useState, useEffect } from "react";
import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/landing/footer/Footer.js';
import Navbar from "./components/landing/header/Navbar.js";
import Mint from "./components/mint/Mint.js";
import Join from "./components/Join/Join.js";
import Email from "./components/Email/Email.js";
import Connect from "./components/Connect/Connect.js";
import Villaa from "./components/villaa/Villaa.js";

import useEagerConnect from "./hooks/useEagerConnect";

function App() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEagerConnect();

  return (
    <>
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/mint/:id' element={<Mint />} />
          <Route path='/join' element={<Join />} />
          <Route path='/Email' element={<Email />} />
          <Route path='/Connect' element={<Connect />} />
          <Route path='/Villaa' element={<Villaa />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
