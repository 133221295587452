import React, { useEffect } from "react";
import './smartcity.scss'
import AOS from "aos";
import "aos/dist/aos.css";

const Smartcity = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <section className=" affiliateprogram" id="about">
                <div className="custom-container">
                            <div className="smartcityouterdiv">
                              
                                    <div className="aboutusimgg_smaet " data-aos="fade-right"
                                        data-aos-easing="linear"
                                        data-aos-duration="1000">
                                        <img
                                            src="\assets\secbaner\secbanerleftssdd.png"
                                            alt="img"
                                            className="img-fluid left-img  "
                                        />
                                    </div>
                               
                              
                                    <div className="rightfstt  aboutvvccc "  >
                                        <p className="aboutvv  ">
                                            <span className="forlineonle"><svg width="46" height="2" viewBox="0 0 46 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="46" height="2" fill="#FF8A00" />
                                            </svg>

                                            </span>Why Smart City
                                        </p>

                                        <h3 className="mypara">
                                        Enabling Accessible, Secure, and Future-Driven Real Estate Investments.                                        </h3>
                                        <p className="myparasecsde">
                                        Smart City NFTs are changing the real estate game by making high-value properties more accessible. They lower investment barriers, allowing more people to enter the market.                                        </p>
                                        <p className="myparasecsde">
                                        These NFTs offer flexibility, enabling easy buying, selling, or leasing while ensuring secure ownership through blockchain technology. This transparency adds trust to your investment.

                                        </p>
                                        <p className="myparasecsde">

                                        By investing in Smart City NFTs, you're embracing the future of real estate. This innovative approach blends cutting-edge technology with profitable opportunities, positioning you ahead in the market.






</p>



                                    </div>
                          

                            </div>
                        </div>
            </section>
        </>
    )
}

export default Smartcity
