import React, { useEffect, useState } from "react";
import "./mint.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbarmint from "../landing/headermint/Navbarmint";
import { Link, useParams } from "react-router-dom";
import BuyNFT from "../../hooks/dataFetchers/BuyNFT";
import axios from "axios";
import Loader from "../../hooks/loader";

const Mint = () => {

  const { id } = useParams();
  console.log(id, "iddddd");
  const [loader, setLoader] = useState(false);

  const [metadataArray, setMetadataArray] = useState(null);

  const [count, setCount] = useState(1); // Initialize state with default value of 2

  const handleIncrement = () => {
    setCount(count + 1); // Increment the count
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1); // Decrement the count, ensuring it doesn't go below 0
    }
  };


  const { BuyNFTHook } = BuyNFT();

  const mintHandle = async () => {
    try {
      setLoader(true);
      const res = await BuyNFTHook(count);
      console.log(res, "res in contract");
      setLoader(false);

    } catch (error) {
      console.log(error, 'errrp');
      setLoader(false);
    }
  }

  const fetchMetadata = async () => {
    try {
      setLoader(true);
      // Replace this with your Pinata gateway URL
      const response = await axios.get(`https://ipfs.io/ipfs/QmdYtbbsEYXr1TMKULevrx8Q4LACQMLokBVabADiUT2HYK/${id}`);
      console.log(response, `res metadata for index ${id}`);

      // Add the new metadata to the array
      setMetadataArray(response.data);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, [id]);

  const convertedURL = metadataArray?.image?.replace("ipfs://", "https://ipfs.io/ipfs/");


  return (
    <>
      {loader && <Loader />}

      <div className="container-fluid  ">
        <div className="  leftrightmarginonly">

          <section className="Riyadh">
            <Navbarmint />
            <div className="tohngfsd">
              <h1 className="rigDHBTNN">Smart City Property NFT</h1>
              <h2>Mint your Smart City</h2>
            </div>
            <div className="inner_div">
              <div className="imgdivv">
                <img src={convertedURL} className="img-fluid mintingimggg" />

              </div>
              <div className="right_side">
                <h3 className="minnnhytf" >Mint Now</h3>

                <div className="partsecond">
                  <button className="plusssdiv" onClick={handleDecrement}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                      <path d="M8.46196 4.25H0.537964V0.75H8.46196V4.25Z" fill="#10312B" />
                    </svg>
                  </button>
                  <input
                    placeholder="2"
                    type="text"
                    className="inputmint"
                    value={count} // Set value to the current count
                    readOnly // Makes sure the input is not editable manually
                  />

                  <button className="plusssdiv" onClick={handleIncrement}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                      <path
                        d="M5.73601 9.23606H0.640015V5.73606H5.73601V0.612061H9.26402V5.73606H14.36V9.23606H9.26402V14.3881H5.73601V9.23606Z"
                        fill="#10312B"
                      />
                    </svg>
                  </button>
                </div>
                <div className="pirceinerdiv">
                  <div className="fstminbld">


                    <p className="mntgp">
                      Minting Price:
                    </p>
                    <p className="mntgpcv">
                      {/* 0.005 ETH */}
                      {metadataArray?.price}
                    </p>
                  </div>
                  <div className="fstminbld">


                    <p className="mntgp ">
                      Minted:
                    </p>
                    <p className="mntgpcv">
                      10
                      <span className="muttedds">10,000</span>
                    </p>
                  </div>

                </div>



                {/* <Link to="/join" className="commonbtnorgss">Mint Your Smart City
                </Link> */}
                <button onClick={() => mintHandle()}>mint</button>
              </div>

            </div>

          </section>

        </div>
      </div>
      <div className="cousallmodaldiv">
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModal2Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <img
                src="\assets\info\infocard.png"
                alt="img"
                className="img-fluid"

              />
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default Mint;
